import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  OpenapiSingleton,
  ReactQueryProvider,
  ThemeProvider,
} from '@kaytrust/react-kaytrust-client';
import CredentialsReception from './CredentialsReception/CredentialsReception';
import './index.css';
import MenuItem from './MenuItem/MenuItem';
import InboxDetail from './InboxDetail/InboxDetail';
import DetailCampaign from './DetailCampaign/DetailCampaign';
import CreateCampaign from './CreateCampaign/CreateCampaign';
import IssuanceCredentials from './IssuanceCredentials/IssuanceCredentials';
import CreateInbox from './CreateInbox/CreateInbox';
import Home from './Home/Home';

OpenapiSingleton.kayTrustIkigai.setBasePath(import.meta.env.VITE_PATH);
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ReactQueryProvider>
    <ThemeProvider>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/credentials-reception" element={<CredentialsReception />} />
          <Route path="/create-inbox" element={<CreateInbox />} />
          <Route path="/inbox-detail" element={<InboxDetail />} />
          <Route path="/detail-campaign" element={<DetailCampaign />} />
          <Route path="/create-campaign" element={<CreateCampaign />} />
          <Route path="/issuance-credentials" element={<IssuanceCredentials />} />
          <Route path="/menu-item" element={<MenuItem />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </ReactQueryProvider>,
);
